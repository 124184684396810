<template>
  <div class="comicReader overflow-y-auto" ref="scrollWarp">
    <div class="content" v-if="!isLoading">
      <div class="atlas" v-if="currentWorks" @click.stop="onClick">
        <div
          class="atlasItem"
          v-for="(itemUrl, index) in currentWorks.contents[itemIndex]['urlSet']"
          :key="itemUrl + index"
        >
         <vue-pinch-zoom :doubleTap="false" @touchstart="handleTouchStart" @touchmove="handleTouchMove" class="pinch-zoom">
          <ImgDecypt
            :isLaz="false"
            v-if="itemUrl"
            :src="itemUrl"
            :key="itemUrl + index"
            :class="{ previewImg: !loadImg }"
            @load="onLoad"
          ></ImgDecypt>
         </vue-pinch-zoom>
        </div>
        <!-- <div class="advertising" v-if="advInfo" @click.stop="goAdv(advInfo)">
                    <ImgDecypt class="advImg" :key="advInfo.cover" :src="advInfo.cover" />
                </div> -->
        <div class="advertising">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in advInfo"
              :key="index"
              @click="goAdv(item)"
            >
              <ImgDecypt class="advImg" :src="item.cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <Loading v-else />
   <Transition name="top">
    <div v-show="visibleHead" class="nav" @click.stop="visibleHead = false">
     <div class="leftIcon" @click="$router.go(-1)">
      <img src="~@/assets/png/left.png" />
     </div>
     <div class="headTitle" v-if="currentWorks">
      <span>第{{ currentWorks.contents[itemIndex].name }}话</span>
     </div>
    </div>
   </Transition>
   <Transition name="bottom">
    <div v-show="visibleHead || visibleTop" class="action" :class="{ running: running }">
     <div v-show="running" class="box" @click.stop="running = false">
       <img class="running" src="@/assets/json/run.gif" />
     </div>
     <div v-show="!running" class="box">
      <div class="prev" @click="upWorks">
       <svg-icon class="icon" iconClass="leftUp" />
       <span>上一章</span>
      </div>
      <div class="func">
       <van-popover
         v-model="visibleRate"
         placement="top"
         trigger="click"
       >
        <div class="tipBox">
         <div v-for="item in actions" :key="item.value" @click="changeRate(item)" :class="{active: running}">
          {{ item.text }}
         </div>
        </div>
        <template #reference>
         <div class="popBox">
          <svg-icon class="icon" iconClass="run" @click.stop="clickRate" />
          <span>自动播放</span>
         </div>
        </template>
       </van-popover>
      </div>
      <div class="func" @click="onChapter">
       <svg-icon class="icon" iconClass="catalogue" />
       <span>目录</span>
      </div>

      <div class="next" @click="downWorks">
       <svg-icon class="icon" iconClass="leftUp" />
       <span>下一章</span>
      </div>
     </div>
    </div>
   </Transition>

    <!--        <van-overlay :show="popupShow" @click="popupShow = false">-->
    <!--            <van-popup v-model="popupShow" :overlay="false" position="top" :style="{ height: '44px',background:'rgb(27,22,76)' }">-->
    <!--                <div @click.stop>-->
    <!--                    <HeadBar @click.stop :titleName="itemData && itemData.name" titleLocation="left"></HeadBar>-->
    <!--                </div>-->
    <!--            </van-popup>-->
    <!--            <van-popup v-model="popupShow" :overlay="false" position="bottom" :style="{ height: '59px',background:'rgb(27,22,76)' }">-->
    <!--                <div class="bottomBar" @click.stop>-->
    <!--                    <div class="up">-->
    <!--                        <img src="@/assets/png/leftUp.png">-->
    <!--                        <span>上一篇</span>-->
    <!--                    </div>-->
    <!--                    <div class="down">-->
    <!--                        <span>下一篇</span>-->
    <!--                        <img src="@/assets/png/rightDown.png">-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </van-popup>-->
    <!--        </van-overlay>-->

<!--    <van-overlay :show="visibleHead" @click="onClick">-->
<!--      <van-popup-->
<!--        v-model="visibleHead"-->
<!--        :overlay="false"-->
<!--        position="top"-->
<!--        :style="{ height: '44px', background: 'rgb(255,255,255)' }"-->
<!--      >-->
<!--        <div @click.stop v-if="currentWorks">-->
<!--          <div class="headBar">-->
<!--            <div class="leftIcon" @click="$router.go(-1)">-->
<!--              <img src="@/assets/png/left.png" />-->
<!--            </div>-->
<!--            <div class="headTitle" style="'justify-content':'left';">-->
<!--              &lt;!&ndash; <span>{{ currentWorks.contents[itemIndex].name }}</span> &ndash;&gt;-->
<!--              <span>第{{ currentWorks.contents[itemIndex].name }}话</span>-->
<!--            </div>-->
<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-if="currentWorks && !currentWorks.hasCollected"-->
<!--              @click="collectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect2.png" />-->
<!--            </div>-->

<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-else-if="currentWorks"-->
<!--              @click="cancelCollectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect2Active.png" />-->
<!--            </div>-->
<!--            <div class="fictionSelect" @click="onChapter">-->
<!--              <img src="@/assets/png/fictionSelect2.png" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </van-popup>-->
      <!-- <van-popup
        v-model="popupShow"
        :overlay="false"
        position="bottom"
        :style="{ height: '109px', background: 'none' }"
      >
        <div class="Barrage" @click.stop>
          <div class="openBarrage">
            <img src="@/assets/png/Barrage.png" />
          </div>
          <div class="sendBarrage">
            <img src="@/assets/png/oBarrage.png" />
          </div>
        </div>
      </van-popup> -->

<!--      <van-popup-->
<!--        v-model="popupShow"-->
<!--        :overlay="false"-->
<!--        position="bottom"-->
<!--        :style="{ height: '59px', background: 'rgb(255,255,255)' }"-->
<!--      >-->
<!--        <div class="bottomBar" @click.stop>-->
<!--          <div class="up" @click="upWorks">-->
<!--            <svg-icon class="icon" iconClass="leftUp" />-->
<!--            <span>上一章</span>-->
<!--          </div>-->
<!--         <div class="catalogue" @click="onChapter">-->
<!--          <svg-icon class="icon" iconClass="run" />-->
<!--          <span>自动播放</span>-->
<!--         </div>-->
<!--         <div class="catalogue" @click="onChapter">-->
<!--          <svg-icon class="icon" iconClass="catalogue" />-->
<!--          <span>目录</span>-->
<!--         </div>-->
<!--          <div class="down" @click="downWorks">-->
<!--            <span>下一章</span>-->
<!--            <svg-icon class="icon" iconClass="rightDown" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </van-popup>-->
<!--    </van-overlay>-->

    <!-- 选章节 -->
    <van-popup
      v-model="chapterShow"
      position="top"
      :style="{
        height: '420px',
        width: '100%',
        background: 'rgb(255,255,255)',
      }"
    >
      <div class="chapterContent" v-if="currentWorks">
        <div class="chapterTitle">
          <span>{{ currentWorks.title }}</span>
        </div>
        <div class="chapterDivision">
          <div class="top">
           <div>目录</div>
           <div class="btn" @click="chapterSort">
<!--            <img src="@/assets/png/sort.png" />-->
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
           </div>
          </div>
          <div class="bottom">
           <div class="name">
            <span>共{{ currentWorks.contents.length }}章</span>
            <span>{{
              currentWorks.updatedDesc.slice(
                0,
                currentWorks.updatedDesc.indexOf("已完结") + 3
              )
             }}</span>
           </div>
          </div>
        </div>
        <div class="chapterList">
          <div
            class="box"
            v-for="(item, index) in currentWorks.contents"
            :key="index"
            @click="jumpWorks(index)"
          >
            <div class="chapterItem" :class="{ activeName: index == itemIndex }">
             <div class="anthologyTxt" :class="{ activeItem: index == itemIndex }">
              <!-- vip -->
              <div class="vipLabel" v-if="item.listenPermission == 0">
               <img src="@/assets/png/vipIcon.png" alt="">
              </div>
              <!-- 金豆 -->
              <div
                class="vipLabel"
                v-if="item.listenPermission == 1"
              >
               <img src="@/assets/png/GoldenIcon.png" alt="">
              </div>
              <!-- 免费 -->
              <div class="vipLabel" v-if="item.listenPermission == 2">
               <img src="@/assets/png/freeIcon.png" alt="">
              </div>
              <!--  集数  -->
              <span>{{ item.episodeNumber + "-" + item.name }}</span>
             </div>
             <div class="isWatch">
<!--               {{ getWatchStatus(bookId, index) }}-->
              {{ item.mediaStatus.hasRead === 0 ? '正在观看' : (item.mediaStatus.hasRead ? '已观看' : '未观看') }}
             </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import VuePinchZoom from 'vue-pinch-zoom';
import ImgDecypt from "@/components/ImgDecypt";
import {doCollect, doPlay, douUcollect} from "@/api/video";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { addVideoHistory, queryHistoryId } from "@/utils";
import * as animationData from '@/assets/json/run.json';
import { mapGetters } from 'vuex';
import {setSessionItem} from "@/utils/longStorage";
export default {
  name: "ComicReader",
  components: {
    ImgDecypt,
    Loading,
    VuePinchZoom,
  },
  data() {
    return {
      defaultOptions: {
       animationData: animationData,
       loop: true, // 自动循环播放
       autoplay: true, // 自动播放
      },
      currentWorks: null,
      show: false,
      isLoading: true,
      loadImg: false,
      popupShow: false,
      chapterShow: false,
      itemIndex: 0,
      sorting: true,
      advInfo: null,
      currentEpisode: 0, // 存储当前集数
      running: false,
      visibleHead: false,
      actions: [
       { text: '0.5x', value: 2.5 },
       { text: '1.0x', value: 1.0 },
       { text: '1.5x', value: 1.5 },
       { text: '2.0x', value: 2.0 },
      ],
      visibleRate: false,
      visibleTop: false,
      rate: 0,
     isScrolling: false
    };
  },
  computed: {
   ...mapGetters({
    watchStatusData: 'watchStatusData',
    buyStatus: "buyStatus",
   }),
   bookId() {
    return this.$store.getters.currentWorks.id;
   }
  },
  beforeDestroy() {
    this.doPlayWorks();
    // 判断集数
    // this.selectEpisode(this.currentEpisode);
    this.$store.dispatch('reader/setMaxEpisode', { novelId: this.currentWorks.id, maxEpisode: this.currentEpisode });
    console.log(this.$store.state.reader.novelId, this.currentEpisode);
    this.cancelAutoMove();
  },
  mounted() {
    let _this = this;
    this.isLoading = true;
    let currentWorks = this.$store.getters.currentWorks;
    console.log(currentWorks);

    let query = this.$route.query;
    if (query) {
      let itemIndex = query.itemIndex;
      this.currentEpisode = itemIndex;
      if (!itemIndex) {
        itemIndex = 0;
      }
      // vuex 获取集数
      // this.currentEpisode = this.$store.state.reader.maxEpisodes[currentWorks.id];

      let itemContents = currentWorks.contents[itemIndex];
      let hasPaid = itemContents.mediaStatus.hasPaid || currentWorks.mediaStatus.hasPaid;
      // this.$store.dispatch('reader/setWatchStatus', { id: currentWorks.id, index: itemIndex, status: '正在观看' });
      //vip
      if (itemContents.listenPermission == 0 && !hasPaid) {
        if (!_this.$store.getters.isVip) {
          this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: currentWorks.kind,
            videoInfo: currentWorks,
            currentEpisode: itemContents,
            id: currentWorks.id,
            closeBtn: () => {
              this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              console.log(this.buyStatus, 11);
              if (this.buyStatus === 1) {
               itemContents.mediaStatus.hasPaid = true;
              } else if (this.buyStatus == null) {
               currentWorks.mediaStatus.hasPaid = true;
              }
              // currentWorks.hasPaid = true;
              _this.itemIndex = itemIndex;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      //金豆收费
      if (itemContents.listenPermission == 1 && !hasPaid) {
        // if (!itemContents.mediaStatus.hasPaid) {
          this.$bus.$emit("vipPopup", {
            state: 4,
            goldenNum: currentWorks.price,
            videoType: currentWorks.kind,
            id: currentWorks.id,
            videoInfo: currentWorks,
            currentEpisode: itemContents,
            closeBtn: () => {
              this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              // if (currentWorks.sellType === 1) {
              //  currentWorks.mediaStatus.hasPaid = true;
              // } else {
              //  itemContents.mediaStatus.hasPaid = true;
              // }
              if (this.buyStatus === 1) {
               itemContents.mediaStatus.hasPaid = true;
              } else if (this.buyStatus == null) {
               currentWorks.mediaStatus.hasPaid = true;
              }
              _this.itemIndex = itemIndex;
              this.$bus.$emit("closeVipPopup");
              this.$store.commit('movies/SET_BUY_STATUS', null);
              // console.log(123123)
            },
          });
          return;
        // }
      }
      currentWorks.contents[itemIndex].mediaStatus.hasRead = 0; // 正在观看
      _this.currentWorks = currentWorks;
      console.log(_this.currentWorks, '当前大大大')
      setSessionItem("currentWorks", JSON.stringify(currentWorks));
      this.$store.commit("video/SET_CURRENT_WORKS", currentWorks);
      _this.itemIndex = itemIndex;
      this.isLoading = false;
    }
    let advList = getAdItem(AdType.CREATION_CENTER);
    if (advList.length > 0) {
      this.advInfo = advList;
    }
  },
  watch: {
   running(bool) {
    // console.log(bool);
    if (bool) {
     const intervalTime = this.getIntervalTime(this.rate);
     this.moveInterval = setInterval(() => this.autoMove(this.rate), intervalTime);
    } else {
     this.visibleHead = true;
     this.cancelAutoMove();
    }
   },
   // itemIndex(newVal, oldVal) {
   //  console.log(newVal, Number(oldVal), 'watch');
   //  if (oldVal !== null && oldVal !== undefined) {
   //   this.setWatchStatus(this.bookId, Number(oldVal), '已观看');
   //  }
   //
   //  this.setWatchStatus(this.bookId, newVal, '正在观看');
   // },
  },
  methods: {
    handleTouchStart(event) {
     this.isScrolling = false
    },
    handleTouchMove(event) {
     if (event.touches.length > 1) {
      event.preventDefault()
      this.isScrolling = false
     } else {
      this.isScrolling = true
     }
    },
    setWatchStatus(id, index, status) {
     this.$store.dispatch('reader/setWatchStatus', { id: id, index: index, status: status });
    },
    getWatchStatus(id, index) {
     return this.$store.getters.watchStatus(id, index);
    },
    cancelAutoMove() {
     if (this.moveInterval) {
      clearInterval(this.moveInterval);
      this.moveInterval = null;
      this.running = false;
     }
    },
    getIntervalTime(rate) {
     if (rate === 2.5) {
      return 50;
     }
     return 10;
    },
    autoMove(speed) {
     const scrollWarp = this.$refs.scrollWarp;
     const { scrollTop, scrollHeight, clientHeight } = scrollWarp;

     if (scrollTop + clientHeight >= scrollHeight) {
      this.cancelAutoMove();
      return;
     }

     let newPosition = scrollTop + speed;
     newPosition = Math.min(newPosition, scrollHeight - clientHeight);

     this.$nextTick(() => {
      if (scrollWarp) {
       scrollWarp.scrollTo(0, newPosition);
      }
     });
    },
    changeRate(item) {
     console.log(item);
     this.rate = item.value;
     this.running = true;
     this.visibleTop = true;
     this.visibleHead = false;
    },
    clickRate() {
     this.visibleRate = !this.visibleRate;
    },
    onClick(e) {
     if (this.visibleTop) {
      this.visibleTop = !this.visibleTop;
     }
     this.visibleHead = !this.visibleHead;
    },
    // selectEpisode(index) {
    //   let maxEpisode = Math.max(index, this.maxEpisode);
    //   this.maxEpisode = maxEpisode;
    //   this.currentWorks.historyIndex = maxEpisode;
    //   addVideoHistory(this.currentWorks);
    //   console.log(index, "大于小", maxEpisode);
    // },
    onLoad() {
      this.loadImg = true;
    },
    onChapter() {
      this.visibleHead = false;
      this.chapterShow = true;
    },
    updateEpisodeState(index) {
     if (this.itemIndex !== undefined && this.itemIndex !== null) {
      this.currentWorks.contents[this.itemIndex].mediaStatus.hasRead = true; // 已观看
     }

     this.currentWorks.contents[index].mediaStatus.hasRead = 0; // 正在观看
     console.log(this.currentWorks, this.itemIndex, "+++++++++++++++++", index);

     this.itemIndex = index;
     this.currentEpisode = this.itemIndex;
     this.currentWorks.historyIndex = this.itemIndex;
     addVideoHistory(this.currentWorks);
     setSessionItem("currentWorks", JSON.stringify(this.currentWorks));
     this.$store.commit("video/SET_CURRENT_WORKS", this.currentWorks);
    },
    handlePopup(state, item, videoType, id, index) {
     console.log(state, id, index);
     this.$bus.$emit("vipPopup", {
      state,
      videoType,
      id,
      videoInfo: this.currentWorks,
      currentEpisode: item,
      closeBtn: () => {
       this.$bus.$emit("closeVipPopup");
       // this.$router.go(-1);
      },
      buySuccess: () => {
       console.log(this.currentWorks.sellType, '类型');
       if (this.buyStatus === 1) {
        item.mediaStatus.hasPaid = true;
       } else {
        this.currentWorks.mediaStatus.hasPaid = true;
       }
       // if (this.currentWorks.sellType === 1) {
       //  this.currentWorks.mediaStatus.hasPaid = true;
       // } else {
       //  item.mediaStatus.hasPaid = true;
       // }
       this.$bus.$emit("closeVipPopup");
       this.$store.commit('movies/SET_BUY_STATUS', null);
       this.itemIndex = index;
       this.currentEpisode = this.itemIndex;
       this.updateEpisodeState(index);
      },
     });
    },
    playEpisode(item, index) {
     let hasPaid = item.mediaStatus.hasPaid || this.currentWorks.mediaStatus.hasPaid;

     // VIP
     if (item.listenPermission == 0 && !hasPaid) {
      if (!this.$store.getters.isVip) {
       this.handlePopup(1, item, this.currentWorks.kind, this.currentWorks.id, index);
       return;
      }
     }

     // 金豆
     if (item.listenPermission == 1 && !hasPaid) {
      this.handlePopup(4, item, this.currentWorks.kind, this.currentWorks.id, index);
      return;
     }

     this.currentEpisode = this.itemIndex;
     this.updateEpisodeState(index);
    },
    jumpWorks(index) {
     let _this = this;
     let itemContents = _this.currentWorks.contents[index];
     this.playEpisode(itemContents, index);
     console.log(this.currentWorks, itemContents, 'n内容11');

      // //vip
      // if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
      //   if (!_this.$store.getters.isVip) {
      //     _this.$bus.$emit("vipPopup", {
      //       state: 1,
      //       videoType: this.currentWorks.kind,
      //       id: this.currentWorks.id,
      //       closeBtn: () => {
      //         _this.$bus.$emit("closeVipPopup");
      //       },
      //       buySuccess: () => {
      //         this.currentWorks.hasPaid = true;
      //         this.itemIndex = index;
      //         this.$bus.$emit("closeVipPopup");
      //         // console.log(123123)
      //       },
      //     });
      //     return;
      //   }
      // }
      // //金豆收费
      // if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
      //   if (!itemContents.hasPaid) {
      //     _this.$bus.$emit("vipPopup", {
      //       state: 2,
      //       goldenNum: this.currentWorks.price,
      //       videoType: this.currentWorks.kind,
      //       id: this.currentWorks.id,
      //       closeBtn: () => {
      //         _this.$bus.$emit("closeVipPopup");
      //       },
      //       buySuccess: () => {
      //         this.currentWorks.hasPaid = true;
      //         this.itemIndex = index;
      //         this.$bus.$emit("closeVipPopup");
      //         // console.log(123123)
      //       },
      //     });
      //     return;
      //   }
      // }
    },
    upWorks() {
     console.log(this.watchStatusData);
     const scrollWarp = this.$refs.scrollWarp;
      this.$nextTick(() => {
       if (scrollWarp) {
        scrollWarp.scrollTo(0, 0);
       }
      });
      let _this = this;
      //上一个章节
      if (this.itemIndex == 0) {
        this.$toast({
          message: "已经是第一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(_this.itemIndex) - 1;
        let itemContents = _this.currentWorks.contents[itemIndex];
        console.log(itemContents);
        this.playEpisode(itemContents, itemIndex);
        //vip
        // if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
        //   if (!_this.$store.getters.isVip) {
        //     _this.$bus.$emit("vipPopup", {
        //       state: 1,
        //       videoType: this.currentWorks.kind,
        //       id: this.currentWorks.id,
        //       closeBtn: () => {
        //         _this.$bus.$emit("closeVipPopup");
        //       },
        //       buySuccess: () => {
        //         this.currentWorks.hasPaid = true;
        //         _this.itemIndex = itemIndex;
        //         this.$bus.$emit("closeVipPopup");
        //         // console.log(123123)
        //       },
        //     });
        //     return;
        //   }
        // }
        // //金豆收费
        // if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
        //   if (!itemContents.hasPaid) {
        //     _this.$bus.$emit("vipPopup", {
        //       state: 2,
        //       goldenNum: this.currentWorks.price,
        //       videoType: this.currentWorks.kind,
        //       id: this.currentWorks.id,
        //       closeBtn: () => {
        //         _this.$bus.$emit("closeVipPopup");
        //       },
        //       buySuccess: () => {
        //         this.currentWorks.hasPaid = true;
        //         _this.itemIndex = itemIndex;
        //         this.$bus.$emit("closeVipPopup");
        //         // console.log(123123)
        //       },
        //     });
        //     return;
        //   }
        // }
        // _this.itemIndex = itemIndex;
      }
      this.currentEpisode--;
      this.currentWorks.historyIndex = _this.itemIndex;
      addVideoHistory(this.currentWorks);
    },
    downWorks() {
      const scrollWarp = this.$refs.scrollWarp;
      this.$nextTick(() => {
       if (scrollWarp) {
        scrollWarp.scrollTo(0, 0);
       }
      });
      let _this = this;
      //下一个章节
      if (_this.itemIndex == _this.currentWorks.contents.length - 1) {
        _this.$toast({
          message: "已经是最后一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(_this.itemIndex) + 1;
        let itemContents = _this.currentWorks.contents[itemIndex];
        console.log(itemContents, itemIndex);
        this.playEpisode(itemContents, itemIndex);

       //vip
       //  if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
       //    if (!_this.$store.getters.isVip) {
       //      _this.$bus.$emit("vipPopup", {
       //        state: 1,
       //        videoType: this.currentWorks.kind,
       //        id: this.currentWorks.id,
       //        closeBtn: () => {
       //          _this.$bus.$emit("closeVipPopup");
       //        },
       //        buySuccess: () => {
       //          this.currentWorks.hasPaid = true;
       //          _this.itemIndex = itemIndex;
       //          this.$bus.$emit("closeVipPopup");
       //          // console.log(123123)
       //        },
       //      });
       //      return;
       //    }
       //  }
       //  //金豆收费
       //  if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
       //    if (!itemContents.hasPaid) {
       //      _this.$bus.$emit("vipPopup", {
       //        state: 2,
       //        goldenNum: this.currentWorks.price,
       //        videoType: this.currentWorks.kind,
       //        id: this.currentWorks.id,
       //        closeBtn: () => {
       //          _this.$bus.$emit("closeVipPopup");
       //        },
       //        buySuccess: () => {
       //          this.currentWorks.hasPaid = true;
       //          _this.itemIndex = itemIndex;
       //          this.$bus.$emit("closeVipPopup");
       //          // console.log(123123)
       //        },
       //      });
       //      return;
       //    }
       //  }
       //  _this.itemIndex = itemIndex;
      }
      // this.currentEpisode++;
      // this.currentWorks.historyIndex = _this.itemIndex;
      // addVideoHistory(this.currentWorks);
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.currentWorks.contents.reverse();
      this.itemIndex = this.currentWorks.contents.length - 1 - this.itemIndex; // 更新选中的元素
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.currentWorks.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.currentWorks.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    async doPlayWorks() {
     let req = {
      kind: this.currentWorks.kind,
      mediaID: this.currentWorks.id,
     };
     await this.$Api(doPlay, req);
    },
    goAdv(item) {
      jumpAdv(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tipBox {
 height: 29px;
 background: #814CFF;
 border-radius: 8px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 0 5px;
 div {
  padding: 0 5px;
  font-size: 14px;
  font-style: italic;
  font-weight: 900;
  color: #fff;
  &.active {
   width: 39px;
   height: 21px;
   border-radius: 8px;
   background: #FFFFFF3B;
  }
 }
}
.comicReader {
  height: 100%;

 .popBox {
  display: flex;
  align-items: center;
  flex-direction: column;
 }

 .nav {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;

  .leftIcon {
   padding: 0 12px;

   img {
    width: 17px;
    height: 17px;
   }
  }

  .headTitle {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   //justify-content: center;
   margin-left: 55px;
   line-height: 40px;
   font-weight: bold;
   font-size: 18px;
   color: #000000;

   span {
    width: 180px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }
  }
 }

 .action {
  position: fixed;
  bottom: 30px;
  height: 59px;
  border-radius: 40px;
  background: rgba(245, 245, 245, 1);
  box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
  width: 312px;
  left: calc(50% - 156px);
  transition: all 0.3s;
  &.running {
   width: 48px;
   left: calc(50% - 24px);
   background: #fff;
   box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
   .box {
    padding: 12px 0;
    justify-content: center;
    .running {
     width: 44px;
     height: 44px;
     margin-left: 5px;
    }
   }
  }
  .box {
   z-index: 2;
   border-radius: 100px;
   padding: 12px 28px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   .running {
    width: 20px;
    height: 20px;
   }

   > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(129, 76, 255, 1);
    font-size: 12px;
    .icon {
     width: 20px;
     height: 20px;
    }

    &.next .icon {
     transform: rotate(180deg);
    }

    &.disabled {
     color: rgba(0, 0, 0, 0.4);
    }
   }
  }
 }

  .content {
    height: 100vh;

    .advertising {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      /deep/ .vanImage {
        border-radius: 8px;
      }
      /deep/ .van-image__img {
        border-radius: 8px;
      }

      .advImg {
        width: 351px;
        height: 97.56px;
        border-radius: 6px;
        margin: 0 auto;
        background: rgb(240, 240, 244);

        /deep/ .van-image {
          border-radius: 6px;
        }

        /deep/ .van-image__img {
          border-radius: 6px;
        }
      }
    }

    .atlas {
      height: 100%;

      .atlasItem {
        width: 100%;
        height: auto;
        .pinch-zoom {
         width: 100%;
         height: auto;
         background-color: transparent !important;
         overflow: hidden;
         display: flex;
         justify-content: center;
         align-items: center;
         /deep/ .pinch-zoom-content {
          width: 100%;
          display: block !important;
         }
        }

        .previewImg {
          width: 100%;
          height: 100px;
        }
      }
    }
  }

  .Barrage {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    .openBarrage {
      margin-right: 6px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .sendBarrage {
      img {
        width: 98px;
        height: 30px;
      }
    }
  }

  .bottomBar {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: rgb(0, 0, 0);
    height: 100%;
    align-items: center;
    padding: 0 12px;

    img {
      width: 20px;
      height: 20px;
    }

    .up {
      img {
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        align-items: center;
      }
    }

    .down {
      img {
        margin-left: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.1);
  }

  .headBar {
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: solid 1px rgb(43, 39, 74);

    .leftIcon {
      padding: 0 12px;
      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 100%;
      height: 100%;
      // display: flex;
      // align-items: center;
      //justify-content: center;
      line-height: 40px;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;
      color: #000000;

      span {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fictionCollect {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
        object-fit: fill;
      }
    }

    .fictionDetails {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
        object-fit: fill;
      }
    }

    .fictionSelect {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .chapterContent {
    .chapterTitle {
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: #212121;
      font-weight: 500;
      margin-top: 53px;
      margin-bottom: 10px;
    }

    .chapterDivision {
      //background: #94d6da;
      //height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
       display: flex;
       justify-content: space-between;
       align-items: center;
       div:first-child {
        padding-left: 12px;
        font-size: 16px;
        color: #333333;
        font-weight: 900;
       }
       .btn {
        margin-right: 12px;
        font-size: 12px;
        color: #777777;
        font-weight: 600;
        //display: flex;
        //align-items: center;
        //img {
        // width: 11.7px;
        // height: 11.5px;
        // margin-right: 4px;
        //}
       }
      }
      .bottom {
       display: flex;
       justify-content: space-between;
       align-items: center;
       margin-top: 10px;
       font-size: 14px;
       color: #555555;
       font-weight: 600;
       .name {
        margin-left: 12px;
       }
      }
    }

    .chapterList {
      margin-top: 5px;
      font-size: 14px;
      color: #9e9e9e;
      padding: 0 12px;
      height: calc(100vh - 112px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .box {
       display: grid;
       grid-template-columns: 1fr;
      }

      .activeName {
       background-image: linear-gradient(#F5F5F5, #F5F5F5),
       linear-gradient(180deg, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%) !important;
       div:nth-child(2) {
        background: #fff;
       }
      }

      .chapterItem {
        margin-top: 10px;
        width: 341px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: double 3px transparent;
        border-radius: 8px;
        background-image: linear-gradient(white, white),
        linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(216, 216, 216, 0.05) 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

       .isWatch {
        background: transparent !important;
        font-size: 13px;
        color: #777777;
        padding-right: 14px;
       }

       .anthologyTxt {
        display: flex;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;
        margin-left: 14px;
        span {
         font-size: 18px;
         font-weight: 900;
         color: rgba(176, 159, 217, 1);
         padding-left: 14px;
        }
       }
       .vipLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        border-radius: 8px;
        font-size: 10px;
        width: 31px;
        height: 19px;
        img {
         width: 18px;
         height: 8px;
        }
       }
      }
    }
  }
}
</style>
